/**
 * Write-only the password as cookie
 */
import React, { useState } from 'react';
import { setSessionPassword } from '@mkitio/gatsby-theme-password-protect/src/utils/utils';

import logo from "../../../images/Montains-colour.png";


const styles = {
  wrapper: {
    height: '100vh',
    background: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center'
  },
  input: {
    width: '100%',
    height: '30px',
    borderRadius: '4px',
    padding: "5px 10px 5px 10px"
  },
  button: {
    width: '100%',
    height: '48px',
    background: '#6CCAD1',
    color: '#fff',
    border: '1px solid #6CCAD1',
    borderRadius: '4px',
    marginTop: '16px',
    textTransform: 'uppercase',
    fontWeight: '300',
    fontFamily: 'sans-serif'
  },
  buttonHover: {
    background: '#fff',
    color: '#0b8792'
  },
};

const PasswordProtect = () => {
  const [password, setPassword] = useState('');
  const [isButtonHovered, buttonHover] = useState(false);

  const onSubmit = event => {
    event.preventDefault();
    setSessionPassword(password);
    window.location.reload(); // eslint-disable-line
  };

  return (
    <div style={styles.wrapper}>
      <img style={{ width: "10%"}} src={logo}></img>
      <h4 style={{ color: 'black' }}>Enter Password</h4>

      <form onSubmit={onSubmit} 
      style={{
        width: '320px',
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        }}>
        <input
          name="password"
          type="password"
          value={password}
          onChange={event => setPassword(event.target.value)}
          style={styles.input}
        />

        <button
          type="submit"
          style={{
            ...styles.button,
            ...(isButtonHovered ? styles.buttonHover : null)
          }}
          onMouseEnter={() => buttonHover(true)}
          onMouseLeave={() => buttonHover(false)}
        >
          Enter
        </button>
      </form>
      <p>Reiel</p>
    </div>
  );
};

export default PasswordProtect;
